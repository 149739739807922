import * as THREE from 'three'
let sphereMesh
let maskSphere

export const CreateSkysphere = scene => {
  const geometry = new THREE.SphereBufferGeometry(500, 60, 40)
  geometry.scale(-1, 1, 1)
  const material = new THREE.MeshBasicMaterial()
  sphereMesh = new THREE.Mesh(geometry, material)
  sphereMesh.position.set(0, 0, 0)
  sphereMesh.name = 'skysphere'
  scene.add(sphereMesh)
  CreateMasksphere(scene)
  SetSkysphereRotation(0)
}

function CreateMasksphere (scene) {
  const geometry = new THREE.SphereBufferGeometry(0.6, 60, 40)
  geometry.scale(-1, 1, 1)
  const material = new THREE.MeshBasicMaterial()
  maskSphere = new THREE.Mesh(geometry, material)
  maskSphere.name = 'masksphere'
  material.transparent = true
  scene.add(maskSphere)
  maskSphere.visible = false
}

export const SetSkysphereMaterial = material => {
  sphereMesh.material = material
}

export const SetMaskSphereTexture = texture => {
  if (texture === '') {
    maskSphere.visible = false
  } else {
    maskSphere.visible = true
    maskSphere.material.map = texture
  }
}

export const SetSkysphereRotation = degrees => {
  sphereMesh.rotation.set(0, THREE.Math.degToRad(180 + degrees), 0)
  maskSphere.rotation.set(0, THREE.Math.degToRad(180 + degrees), 0)
}
