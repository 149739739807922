import * as THREE from 'three'
import {
  GetCameraLastX,
  GetCameraLastY,
  SetCameraRotation,
  TransformFOV
} from './camera'
import { Raycast, ResizeWindow } from './main'

let isUserInteracting = false
let pointerStartTime
let onMouseDownLon
let onMouseDownLat
const lastMousePos = new THREE.Vector2()
const mouse = new THREE.Vector2()
let clientXOrigin
let clientYOrigin

export const RegisterEvents = () => {
  window.addEventListener('resize', onWindowResize)
  window.addEventListener('mousedown', onPointerStart)
  window.addEventListener('touchstart', onTouchStart)
  window.addEventListener('mousemove', onPointerMove)
  window.addEventListener('touchmove', onPointerMove)
  window.addEventListener('mouseup', onPointerUp)
  window.addEventListener('touchend', onPointerUp)
  window.addEventListener('wheel', onDocumentMouseWheel)
}

export const RemoveEvents = () => {
  window.removeEventListener('resize', onWindowResize)
  window.removeEventListener('mousedown', onPointerStart)
  window.removeEventListener('touchstart', onTouchStart)
  window.removeEventListener('mousemove', onPointerMove)
  window.removeEventListener('touchmove', onPointerMove)
  window.removeEventListener('mouseup', onPointerUp)
  window.removeEventListener('touchend', onPointerUp)
  window.removeEventListener('wheel', onDocumentMouseWheel)
}

export const onWindowResize = () => {
  ResizeWindow()
}

export const onPointerStart = event => {
  if (event.srcElement.localName !== 'canvas') return
  switch (event.button) {
    case 0:
      isUserInteracting = true
      pointerStartTime = Date.now()
      clientXOrigin = GetClientX(event)
      clientYOrigin = GetClientY(event)
      onMouseDownLon = GetCameraLastX()
      onMouseDownLat = GetCameraLastY()
      lastMousePos.x = (clientXOrigin / window.innerWidth) * 2 - 1
      lastMousePos.y = -(clientYOrigin / window.innerHeight) * 2 + 1
      break
  }
}

export const onTouchStart = event => {
  if (event.srcElement.localName !== 'canvas') return
  isUserInteracting = true
  pointerStartTime = Date.now()
  clientXOrigin = GetClientX(event)
  clientYOrigin = GetClientY(event)
  onMouseDownLon = GetCameraLastX()
  onMouseDownLat = GetCameraLastY()
  lastMousePos.x = (clientXOrigin / window.innerWidth) * 2 - 1
  lastMousePos.y = -(clientYOrigin / window.innerHeight) * 2 + 1
}

export const onPointerMove = event => {
  if (isUserInteracting && event) {
    const clientX = GetClientX(event)
    const clientY = GetClientY(event)
    const lon = (clientXOrigin - clientX) * 0.1 + onMouseDownLon
    const lat = (clientY - clientYOrigin) * 0.1 + onMouseDownLat
    SetCameraRotation(lon, lat)
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
  }
}

function GetClientX (event) {
  return event.clientX || (event.touches && event.touches[0].clientX) || 0
}

function GetClientY (event) {
  return event.clientY || (event.touches && event.touches[0].clientY) || 0
}

export const onPointerUp = event => {
  isUserInteracting = false
  if (Math.abs(Date.now() - pointerStartTime) < 150) {
    mouse.x = ((event.clientX || event.layerX) / window.innerWidth) * 2 - 1
    mouse.y = -((event.clientY || event.layerY) / window.innerHeight) * 2 + 1
    Raycast(mouse)
  }
}

export const onDocumentMouseWheel = event => {
  if (event.srcElement.localName !== 'canvas') return
  let correctedDelta = 0
  if (event.deltaY > 0) {
    correctedDelta = 1
  } else if (event.deltaY < 0) {
    correctedDelta = -1
  }
  TransformFOV(correctedDelta * 5)
}
