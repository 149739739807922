import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { GetDb, GetImagesFromDb, AddImage, DeleteImageFromDb } from './DB'
import { StartVisit, ImageLoaded } from './main'

let materialsList = []
let collidersList = []
let videoMeshList = []
const scaleFactor = 0.001

let interfaceContent

let db
let dbimages

export const SetDataToLoad = async data => {
  interfaceContent = data
  try {
    db = await GetDb()
    dbimages = await GetImagesFromDb(db)
  } catch (e) {
    console.error('Local DB not available')
  }
}

export const Load360Materials = async (images, data) => {
  materialsList = []
  for (let i = 0; i < images.length; i++) {
    await loadImage(images[i], data)
    if (i === 0) {
      StartVisit()
    }
    ImageLoaded(i, images.length, images[i].id)
  }
}

const loadImage = async function (image) {
  const texture = new THREE.Texture()
  const b64 = GetBase64FromDB(image.id, false)
  if (b64) {
    const processed = await addImageProcess(b64)
    texture.image = processed
    texture.needsUpdate = true
  } else {
    const response = await toDataURL(
      interfaceContent.views[image.id].media['en-US'][0].objects.original
    )
    const processed = await addImageProcess(response)
    texture.image = processed
    texture.needsUpdate = true
    if (db) {
      await AddImage(
        db,
        image.id,
        response,
        interfaceContent.views[image.id].media['en-US'][0].updatedAt
      )
    }
  }
  texture.minFilter = THREE.LinearFilter
  let mask = ''
  if (interfaceContent.masks && interfaceContent.masks[image.id]) {
    const textureMask = new THREE.Texture()
    const b64Mask = GetBase64FromDBMask(image.id, true)
    if (b64Mask) {
      const processed = await addImageProcess(b64Mask)
      textureMask.image = processed
      textureMask.needsUpdate = true
    } else {
      const response = await toDataURL(
        interfaceContent.masks[image.id].media['en-US'][0].objects.original
      )
      const processed = await addImageProcess(response)
      textureMask.image = processed
      textureMask.needsUpdate = true
      if (db) {
        await AddImage(
          db,
          'm_' + image.id,
          response,
          interfaceContent.masks[image.id].media['en-US'][0].updatedAt
        ) // WAITING FOR UPDATEDAT KEY TO BE IMPLEMENTED AGAIN
      }
    }
    mask = textureMask
  }
  const material = new THREE.MeshBasicMaterial({ map: texture })
  return materialsList.push({
    identifier: image.id,
    pins3D: image.pins3D,
    videos: image.videos,
    material,
    mask,
    position: image.position
  })
}

function GetBase64FromDB (imageID) {
  if (dbimages) {
    for (let i = 0; i < dbimages.length; i++) {
      if (dbimages[i].id === imageID) {
        const update =
          interfaceContent.views[imageID].media['en-US'][0].updatedAt
        if (dbimages[i].timestamp === update) {
          return dbimages[i].base64
        } else {
          DeleteImageFromDb(db, imageID)
          return undefined
        }
      }
    }
  }
  return undefined
}

function GetBase64FromDBMask (imageID) {
  if (dbimages) {
    for (let i = 0; i < dbimages.length; i++) {
      if (dbimages[i].id === 'm_' + imageID) {
        const update =
          interfaceContent.masks[imageID].media['en-US'][0].updatedAt
        if (dbimages[i].timestamp === update) {
          return dbimages[i].base64
        } else {
          DeleteImageFromDb(db, 'm_' + imageID)
          return undefined
        }
      }
    }
    return undefined
  }
}

const addImageProcess = async src => {
  return new Promise(resolve => {
    const img = new Image() /* eslint-disable-line */
    img.onload = () => resolve(img)
    img.src = src
  })
}

function toDataURL (url) {
  const xhr = new XMLHttpRequest() /* eslint-disable-line */
  return new Promise(resolve => {
    xhr.onload = function () {
      const reader = new FileReader() /* eslint-disable-line */
      reader.onloadend = function () {
        return resolve(reader.result)
      }

      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  })
}

export const LoadColliders = (povs, scene, rotation) => {
  collidersList = []
  return Promise.all(
    povs.map((pov, index) => loadMesh(pov, scene, index, rotation))
  )
}

const loadMesh = async function (pov, scene, index, rotation) {
  if (pov.collider && pov.collider !== undefined && pov.collider !== '') {
    const loader = new OBJLoader()
    const object = await loader.loadAsync(
      interfaceContent.colliders[pov.collider].media['en-US'][0].objects
        .original
    )
    const name = index
    object.name = name
    object.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.material = new THREE.MeshBasicMaterial({
          color: new THREE.Color('skyblue'),
          transparent: true,
          opacity: 0.8
        })
        child.name = name
      }
    })
    object.scale.set(scaleFactor, scaleFactor, scaleFactor)
    if (rotation) {
      object.rotation.set(0, THREE.Math.degToRad(rotation), 0)
    }
    scene.add(object)
    object.visible = false
    collidersList.push(object)
  }
}

export const LoadVideoMeshes = (videos, scene, videoMaterial, rotation) => {
  if (!videos) return
  videoMeshList = []
  return Promise.all(
    videos.map((video, index) =>
      loadVideoMesh(video, scene, videoMaterial, index, rotation)
    )
  )
}

const loadVideoMesh = async function (
  video,
  scene,
  videoMaterial,
  index,
  rotation
) {
  if (video.source) {
    const loader = new OBJLoader()
    const object = await loader.loadAsync(
      interfaceContent.screens[video.source].media['en-US'][0].objects.original
    )
    const name = index
    object.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.material = videoMaterial[index]
        child.name = name
        child.layers.enable(1)
      }
    })
    object.scale.set(scaleFactor, scaleFactor, scaleFactor)
    if (rotation) {
      object.rotation.set(0, THREE.Math.degToRad(rotation), 0)
    }
    scene.add(object)
    videoMeshList.push({
      identifier: video.id,
      object: object
    })
  }
}

export const Get360Data = id => {
  for (let i = 0; i < materialsList.length; i++) {
    if (materialsList[i].identifier === id) {
      return materialsList[i]
    }
  }
  return ''
}

export const GetColliders = () => {
  return collidersList
}

export const ChangeMeshesPosition = (cameraPosX, cameraPosY, cameraPosZ) => {
  for (let i = 0; i < collidersList.length; i++) {
    collidersList[i].position.set(
      -cameraPosY * scaleFactor,
      -cameraPosZ * scaleFactor,
      -cameraPosX * scaleFactor
    )
  }
  for (let i = 0; i < videoMeshList.length; i++) {
    videoMeshList[i].object.position.set(
      -cameraPosY * scaleFactor,
      -cameraPosZ * scaleFactor,
      -cameraPosX * scaleFactor
    )
  }
}

export const SetScreensVisibility = (ids, filter) => {
  for (let i = 0; i < videoMeshList.length; i++) {
    videoMeshList[i].object.visible = false
    if (ids && filter) {
      for (let j = 0; j < ids.length; j++) {
        if (videoMeshList[i].identifier === ids[j]) {
          videoMeshList[i].object.visible = true
          break
        }
      }
    }
  }
}

export const CleanupResources = () => {
  materialsList = []
  collidersList = []
  videoMeshList = []
}
