<template>
  <div class="Carousel">
    <swiper
      v-if="images.length > 1"
      ref="mySwiper"
      class="Swiper"
      :options="swiperOptions"
      @slideChange="onSlideChange()"
    >
      <swiper-slide v-for="(image, index) in images" :key="index">
        <div
          v-if="image.video"
          class="Carousel-slide"
          @click="onSelectSlide(image.target_id)"
        >
          <video class="Carousel-img" autoplay loop muted playsinline>
            <source :src="image.url" type="video/mp4" />
          </video>
          <p v-if="image.description" class="Carousel-desc">
            {{ image.description }}
          </p>
        </div>
        <div
          v-else
          class="Carousel-slide"
          @click="onSelectSlide(image.target_id)"
        >
          <p v-if="image.description" class="Carousel-desc">
            {{ image.description }}
          </p>
          <img class="Carousel-img" :src="image.url" />
        </div>
      </swiper-slide>
    </swiper>
    <div class="Carousel-unique" v-else>
      <div
        v-if="images[0].video"
        class="Carousel-slide"
        @click="onSelectSlide(images[0].target_id)"
      >
        <video
          class="Carousel-img"
          autoplay
          loop
          muted
          playsinline
          :style="{ 'z-index': 0 }"
        >
          <source :src="images[0].url" type="video/mp4" />
        </video>
      </div>
      <div
        v-else
        class="Carousel-slide"
        @click="onSelectSlide(images[0].target_id)"
      >
        <img class="Carousel-img" :src="images[0].url" />
      </div>
    </div>
    <div v-if="images.length > 1" class="swiper-pagination" />
    <div
      v-if="images.length > 1"
      class="swiper-button-prev"
      :style="{ color: colorNav }"
    ></div>
    <div
      v-if="images.length > 1"
      class="swiper-button-next"
      :style="{ color: colorNav }"
    ></div>
    <div
      class="Fullscreen"
      v-if="fullscreenIcon"
      :style="{ background: $store.getters['theme/theme'].secondary }"
      @click="$emit('Fullscreen')"
    />
    <div
      class="CloseFullscreen"
      v-if="closeFullscreenIcon"
      :style="{ background: $store.getters['theme/theme'].secondary }"
      @click="$emit('CloseFC')"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Carousel',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    images: {
      type: Array,
      default: () => [
        {
          url: 'http://techslides.com/demos/sample-videos/small.mp4',
          description: 'Description 1',
          target_id: 'id_1'
        },
        {
          url:
            'https://s3.eu-west-3.amazonaws.com/digital-events.relax-room.dev/bg2_c.jpg',
          description: 'Description 2',
          target_id: 'id_2'
        },
        {
          url:
            'https://s3.eu-west-3.amazonaws.com/digital-events.relax-room.dev/bg3_c.jpg',
          description: 'Description 3',
          target_id: 'id_3'
        }
      ]
    },
    factor: { default: 1 },
    fullscreenIcon: { default: false },
    closeFullscreenIcon: { default: false },
    colorNav: { default: '#FFFFFF' }
  },
  data () {
    return {
      activeIndex: 0,
      show: false,
      swiperOptions: {
        loop: true,
        allowTouchMove: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    maxIndex () {
      return this.images.length - 1
    }
  },
  mounted () {},
  methods: {
    onSelectSlide (target) {},
    onSlideChange () {
      if (this.activeIndex < this.maxIndex) {
        this.activeIndex = this.activeIndex + 1
        this.$emit('onChangeDiapo', this.activeIndex)
      } else {
        this.activeIndex = 0
        this.$emit('onChangeDiapo', this.activeIndex)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/main.scss';

p {
  font-family: 'BaronDidotT', Helvetica, Arial, sans-serif;
  color: white;
  text-align: center;
}

.Carousel {
  width: 100%;
  height: 100%;
  @media (orientation: landscape) {
    margin-left: 60px;
    margin-bottom: 0;
    width: calc(100% - 60px);
  }
  @media (orientation: portrait) {
    margin-left: 0;
    margin-bottom: 60px;
    height: calc(100% - 60px);
  }
}

.Carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Swiper,
.Carousel-slide,
.Carousel-img {
  position: relative;
  width: 100%;
  height: 100%;
}

.Carousel-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  object-fit: contain;
}

.Carousel-unique {
  height: 100%;
  width: 100%;
}

.Carousel-desc {
  position: absolute;
  bottom: 3.5rem;
  max-width: 75%;
  z-index: 205;
  color: white;
  text-align: center;
  font-family: 'Gotham Bold';
  -webkit-text-stroke: 0.8px black;
  @media screen and (max-height: 400px) and (orientation: landscape),
    screen and (max-width: 680px) and (orientation: portrait) {
    -webkit-text-stroke: 0.6px black;
    bottom: 2rem;
  }
  @include fluid-type($min_width, $max_width, $min_font * 1.3, $max_font * 1);
}

.swiper-button-next {
  // background-image: url('../../assets/icons/chevron_right.svg');
  // height: 2rem;
  right: 0;
}

.swiper-button-prev {
  // background-image: url('../../assets/icons/chevron_left.svg');
  left: 0;
  @media (orientation: landscape) {
    margin-left: 60px;
  }
  @media (orientation: portrait) {
    margin-left: 0;
  }
}
.swiper-button-next,
.swiper-button-prev {
  width: 4rem;
  height: 4rem;
  margin-top: -2rem;
  // background-size: 1rem 1rem !important;
  @media screen and (max-height: 400px) and (orientation: landscape),
    screen and (max-width: 680px) and (orientation: portrait) {
    width: 2rem;
    height: 2rem;
    margin-top: -1.5rem;
    // background-size: 2rem 2rem;
  }
  &:after {
    font-size: 25px !important;
  }
}
.CloseFullscreen {
  position: absolute;
  right: 1em;
  top: 1em;
  background-color: white;
  mask-image: url('../../assets/icons/close.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  width: 3em;
  height: 3em;
  z-index: 200;
}
.Fullscreen {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: white;
  mask-image: url('../../assets/icons/overscan.svg');
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  mask-position: center;
  mask-repeat: no-repeat;
  width: 2em;
  height: 2em;
  z-index: 200;
}

.swiper-pagination {
  position: absolute;
  bottom: 2%;
  width: 100% !important;
  @media (orientation: landscape) {
    margin-left: -60px;
  }
  @media (orientation: portrait) {
    margin-left: 0;
    bottom: calc(60px + 2%);
  }
}
</style>
