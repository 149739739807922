import router from '@/router'

export default {
  methods: {
    SetCardQuery (route, id) {
      const query = { ...route.query }
      if (id) {
        query.card = id
      } else {
        delete query.card
      }
      if (query.card !== route.query.card) {
        router.replace({ query: query })
      }
    },
    SetPOVQuery (route, index) {
      const query = { ...route.query }
      query.pov = index
      if (query.pov !== route.query.pov) {
        router.replace({ query: query })
      }
    }
  }
}
