<template>
  <div
    class="Pin3D__Object"
    :style="{ left: data.x + 'px', top: data.y + 'px' }"
    @click="GoToCard(data.id)"
  >
    <div class="Pin3D__Icon" />
  </div>
</template>

<script>
import SetCardQuery from '@/mixins/query'
import Vue from 'vue'

export default {
  props: {
    data: { default: '' }
  },
  mixins: [SetCardQuery],
  methods: {
    GoToCard (id) {
      this.$store.dispatch('module_visit/SetMapOpen', false)
      this.$store.dispatch('module_visit/SetCardOpen', false)
      this.SetCardQuery(this.$route, id)
      const store = this.$store
      Vue.nextTick(function () {
        store.dispatch('cards/SetCardID', id)
        store.dispatch('module_visit/SetCardOpen', true)
      })
    }
  }
}
</script>

<style scoped>
.Pin3D__Object {
  position: absolute;
  width: 2.2em;
  height: 2.2em;
  display: flex;
  flex-direction: column;
  background: black;
  border-radius: 100%;
  pointer-events: auto;
  border: solid white 3px;
}

.Pin3D__Icon {
  width: 70%;
  height: 70%;
  margin: auto;
  background-color: white;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  overflow: hidden;
  box-sizing: border-box;
  mask-image: url('../../../../../assets/icons/ArmaniGA.svg');
}
</style>
