<template>
  <div class="bg" v-if="loaded">
    <Carousel
      :images="list"
      :colorNav="$store.getters['theme/theme'].secondary"
      :closeFullscreenIcon="false"
      @CloseFC="$router.push({ name: 'showroom' })"
    />
  </div>
</template>

<script>
import Carousel from '@/components/carousel/Carousel.vue'

export default {
  data () {
    return {
      list: [],
      loaded: false
    }
  },
  mounted () {
    const id = this.$route.params.id
    for (let i = 0; i < this.images.length; i++) {
      this.list.push({
        url: this.GetImageURL(id, this.images[i].url),
        description: this.images[i].description,
        target_id: this.images[i].target_id
      })
    }
    this.loaded = true
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Carousel
  },
  methods: {
    GetImageURL (id, name) {
      const wording = this.$store.getters['langs/wording']
      return wording.showroom[id].views[name].media['en-US'][0].objects.original
    }
  }
}
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
}
</style>
