var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Map__Container",attrs:{"id":"Container"}},[_c('div',{staticClass:"Map__ContainerScroll"},[_vm._m(0),_c('img',{ref:"Plane",staticClass:"Map_Preview",attrs:{"src":_vm.GetImage()},on:{"load":function($event){return _vm.PlacePins()}}}),_c('div',{ref:"Overlay",staticClass:"Zoning_Overlay"},[_c('div',{staticClass:"Zoning_OverlayRelative"},_vm._l((_vm.$store.getters['module_visit/visitData']
            .pov),function(element,index){return _c('div',{key:index,ref:'chip' + index,refInFor:true,staticClass:"Zoning__Tick Zoning__Pin",style:({
            background:
              index === _vm.$store.getters['module_visit/pov']
                ? 'black'
                : 'rgb(215, 50,30)'
          }),on:{"click":function($event){return _vm.GoToView(index)}}},[_c('p',{staticClass:"Zoning_Text"},[_vm._v(_vm._s(index + 1))])])}),0)]),_c('div',{staticClass:"Map__Title"},[_c('p',{staticClass:"Map__Title_Text"},[_vm._v(" "+_vm._s('Views ' + '(' + _vm.$store.getters['module_visit/visitData'].pov.length + ')')+" ")])]),_c('div',{staticClass:"POV_List"},_vm._l((_vm.$store.getters['module_visit/visitData']
          .pov),function(element,index){return _c('div',{key:index,staticClass:"POV_Element",style:({
          background:
            index === _vm.$store.getters['module_visit/pov']
              ? 'rgba(100,100,100,0.1)'
              : ''
        })},[_c('div',{staticClass:"POV_Main"},[_c('div',{staticClass:"POV_Main_Pastille",style:({
              background:
                index === _vm.$store.getters['module_visit/pov']
                  ? 'black'
                  : 'rgb(215, 50,30)'
            }),on:{"click":function($event){return _vm.GoToView(index)}}},[_c('p',{staticClass:"POV_Main_Pastille_Text"},[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"POV_Main_Name",on:{"click":function($event){return _vm.GoToView(index)}}},[_c('p',{staticClass:"POV_Main_Name_Text",style:({
                'font-weight':
                  index === _vm.$store.getters['module_visit/pov'] ? 800 : 600
              })},[_vm._v(" "+_vm._s(element.name)+" ")])])])])}),0),(_vm.Get3DPins().length > 0)?_c('div',{staticClass:"Map__Title"},[_c('p',{staticClass:"Map__Title_Text"},[_vm._v(" "+_vm._s('Informations ' + '(' + _vm.Get3DPins().length + ')')+" ")])]):_vm._e(),(_vm.Get3DPins().length > 0)?_c('div',{staticClass:"Pin_List"},_vm._l((_vm.Get3DPins()),function(pin,index){return _c('div',{key:'A' + index,staticClass:"POV_Element"},[_c('div',{staticClass:"POV_Main"},[_c('div',{staticClass:"POV_Main_Pastille",on:{"click":function($event){return _vm.OpenCard(pin)}}},[_c('p',{staticClass:"POV_Main_Pastille_Text"},[_vm._v(_vm._s(_vm.GetAlphaNumeric(index)))])]),_c('div',{staticClass:"POV_Main_Name",on:{"click":function($event){return _vm.OpenCard(pin.id)}}},[_c('p',{staticClass:"POV_Main_Name_Text"},[_vm._v(" "+_vm._s(_vm.Get3DPinTitle(pin.id))+" ")])])])])}),0):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Map__Title"},[_c('p',{staticClass:"Map__Title_Text"},[_vm._v("Zoning")])])}]

export { render, staticRenderFns }