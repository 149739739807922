<template>
  <div class="Pos360_Container">
    <div v-show="loaded" id="threejscontainer" class="Pos360_ContainerThree" />
    <div v-show="loaded" class="Pos360__Pins3DContainer">
      <Pin3D v-for="(pin, index) in pins3D" :key="index" :data="pin"></Pin3D>
    </div>
    <div class="ui" v-if="loaded">
      <PanelButton @Clicked="OpenTopView()" />
      <div
        class="VideoSwitch"
        @click="SwitchVideos()"
        v-if="!(isMobile && isSafari)"
      >
        <div
          class="VideoSwitchOn"
          v-if="$store.getters['module_visit/videosActive']"
        />
        <div
          class="VideoSwitchOff"
          v-if="!$store.getters['module_visit/videosActive']"
        />
      </div>
    </div>
    <div class="ui2" v-if="loaded">
      <div class="sliderContainer">
        <p class="zoomTextA">+</p>
        <p class="zoomTextB">-</p>
        <div class="zoomLineA" />
        <div class="zoomLineB" />
        <div class="zoomLineC" />
        <input
          class="zoomSlider custom-slider"
          type="range"
          @input="ZoomChanged()"
          v-model="zoom"
          min="0"
          max="1"
          step="0.02"
        />
      </div>
    </div>

    <InfiniteLoader :color="'0,0,0'" v-show="!loaded" class="Pos360__Loader" />
    <div class="POVLoader" v-show="loadPOV">
      <InfiniteLoader :color="'255,255,255'" class="Pos360__Loader2" />
    </div>
    <div class="FullLoaderContainer">
      <div class="FullLoader" id="FullLoader" />
    </div>
    <Panel v-if="loaded" />
    <CarrouselFullscreen />
  </div>
</template>

<script>
import * as main from './three/main'
import InfiniteLoader from '@/components/loader/InfiniteLoader.vue'
import { EventBus } from '@/event-bus.js'
import Pin3D from './components/Pin3D.vue'
import Panel from './components/Panel'
import SetPOVQuery from '@/mixins/query'
import PanelButton from '@/components/panelButton/PanelButton.vue'
import CarrouselFullscreen from './components/CarrouselFullscreen'

export default {
  data () {
    return {
      loaded: false,
      selectedView: 0,
      zoom: 0.5,
      loadPOV: false,
      pins3D: []
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    },
    isSafari () {
      return (
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1
      )
    },
    isMobile () {
      return /iPhone|iPad/i.test(navigator.userAgent)
    }
  },
  mixins: [SetPOVQuery],
  components: {
    Pin3D,
    InfiniteLoader,
    Panel,
    PanelButton,
    CarrouselFullscreen
  },
  async mounted () {
    this.$store.dispatch('module_visit/SetScrollValue', 0)
    this.$store.dispatch('module_visit/SetVideosActive', !this.isSafari)
    this.$store.dispatch('module_visit/SetFullscreenData', '')
    EventBus.$on('RaycastGot', index => {
      this.SetPOVQuery(this.$route, index)
      this.selectedView = index
    })
    EventBus.$on('VisitLoaded', () => {
      this.loaded = true
      this.CheckURL()
    })
    EventBus.$on('SetZoom', value => {
      this.zoom = value
    })
    EventBus.$on('POVloading', value => {
      this.loadPOV = value
    })
    EventBus.$on('ImagesProgress', value => {
      if (document.getElementById('FullLoader')) {
        if (value >= 1) {
          document.getElementById('FullLoader').style.width = '0%'
        } else {
          document.getElementById('FullLoader').style.width = value * 100 + '%'
        }
      }
    })
    EventBus.$on('pinsChanged', data => {
      this.UpdatePins3D(data)
    })
    EventBus.$on('GoToPOV', index => {
      this.GoToPOVByIndex(index)
    })
    this.$store.dispatch('module_visit/SetVisitData', this.data)
    this.loadVisit()
  },
  beforeDestroy () {
    this.$store.dispatch('module_visit/SetMapOpen', false)
    this.$store.dispatch('module_visit/SetCardOpen', false)
    main.Close()
    EventBus.$off('RaycastGot')
    EventBus.$off('VisitLoaded')
    EventBus.$off('SetZoom')
    EventBus.$off('POVloading')
    EventBus.$off('ImagesProgress')
    EventBus.$off('pinsChanged')
  },
  methods: {
    async loadVisit () {
      try {
        this.$store.dispatch(
          'module_visit/SetMapURL',
          this.wording.showroom[this.$route.params.id].top.media['en-US'][0]
            .objects.original
        )
        await main.Init(
          this.data,
          this.wording.showroom[this.$route.params.id],
          !(this.isMobile && this.isSafari)
        )
      } catch (e) {
        console.error(e)
      } finally {
        this.loaded = true
      }
    },
    GoToView () {
      main.GoToPOV(this.selectedView)
    },
    GoToPOVByIndex (index) {
      main.GoToPOV(index)
    },
    GoHome () {
      main.Close()
      this.$router.push({ name: 'selection' })
    },
    ZoomChanged () {
      main.SetZoom(this.zoom)
    },
    UpdatePins3D (data) {
      this.pins3D = data
    },
    OpenTopView () {
      this.$store.dispatch('module_visit/SetCardOpen', false)
      this.$store.dispatch('module_visit/SetMapOpen', true)
    },
    CheckURL () {
      if (this.$route.query.pov) {
        const po = parseInt(this.$route.query.pov)
        if (po) {
          this.GoToPOVByIndex(po)
        }
      }
      if (this.$route.query.card) {
        const card = this.$route.query.card
        this.$store.dispatch('module_visit/SetMapOpen', false)
        this.$store.dispatch('module_visit/SetCardOpen', true)
        this.$store.dispatch('cards/SetCardID', card)
      }
      if (this.$route.query.debug) {
        main.SetDebugStats(true)
      }
    },
    SwitchVideos () {
      if (this.isSafari) {
        if (!this.$store.getters['module_visit/videosActive']) {
          alert(
            '360 videos are not optimized for this browser, please open this website from the Firefox or Chrome browser for a better experience'
          )
        }
      }
      this.$store.dispatch(
        'module_visit/SetVideosActive',
        !this.$store.getters['module_visit/videosActive']
      )
      EventBus.$emit('notify', {
        type: 'warning',
        message: this.$store.getters['module_visit/videosActive']
          ? 'switching 360 videos on'
          : 'switching 360 videos off',
        hidden: false
      })
      main.UpdateVideoVisibility(
        this.$store.getters['module_visit/videosActive']
      )
    }
  }
}
</script>

<style scoped lang="scss">
.Pos360_Container {
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  touch-action: none;
}

.Pos360_ContainerThree {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  touch-action: none;
}

.Pos360__Pins3DContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.Pos360__Loader {
  margin: auto;
  width: 50px;
  height: 50px;
  margin-top: 20%;
  align-self: center;
}

.POVLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
}

.Pos360__Loader2 {
  margin: auto;
  width: 50px;
  height: 50px;
}

.FullLoaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
}

.FullLoader {
  bottom: 0;
  width: 0%;
  height: 7px;
  background: white;
  align-self: flex-end;
}

.ui {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}

.ui2 {
  position: fixed;
  right: 1em;
  top: 1em;
  display: flex;
  flex-direction: row;
}

.dropdown {
  margin-left: 1em;
  height: 1.5em;
}

.sliderContainer {
  position: absolute;
  right: -4em;
  top: 3em;
  width: 10em;
  height: 10em;
  box-sizing: border-box;
  display: flex;
}

.zoomLineA {
  position: absolute;
  width: 1em;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 1px 1px 20px grey;
  height: 1px;
  left: 4.5em;
  top: 0em;
  margin-top: -1px;
}

.zoomLineB {
  position: absolute;
  width: 0.5em;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 1px 1px 20px grey;
  height: 1px;
  left: 4.75em;
  top: 5em;
  margin-top: -1px;
}

.zoomLineC {
  position: absolute;
  width: 1em;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 1px 1px 20px grey;
  height: 1px;
  left: 4.5em;
  top: 10em;
  margin-top: -1px;
}

.zoomTextA {
  position: absolute;
  top: -0.5em;
  right: 6.5em;
  color: white;
}

.zoomTextB {
  position: absolute;
  top: 9.3em;
  right: 6.5em;
  color: white;
}

input[type='range'] {
  width: 100%;
  right: -5em;
  transform: rotate(-90deg);
  pointer-events: auto;
}

input[type='range'] {
  width: 100%;
  margin: 4.4px 0;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 1px 1px 20px grey;
  height: 2px;
  align-self: center;
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
}

input[type='range']:active {
  &::-webkit-slider-thumb {
    background: rgba(255, 179, 0, 1);
  }
  &::-moz-range-thumb {
    background: rgba(255, 179, 0, 1);
  }
  &::-ms-thumb {
    background: rgba(255, 179, 0, 1);
  }
}

input[type='range']:hover {
  &::-webkit-slider-thumb {
    background: rgba(255, 179, 0, 1);
  }
  &::-moz-range-thumb {
    background: rgba(255, 179, 0, 1);
  }
  &::-ms-thumb {
    background: rgba(255, 179, 0, 1);
  }
}

input[type='range']::-webkit-slider-thumb {
  align-self: center;
  width: 20px;
  height: 20px;
  background: white;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  align-self: center;
  width: 20px;
  height: 20px;
  background: white;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range']::-ms-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
}

*:focus {
  outline: none;
}

.VideoSwitch {
  position: absolute;
  right: 5rem;
  width: 2em;
  height: 2em;
  top: 1.2em;
  pointer-events: auto;
}

.VideoSwitchOn {
  width: 100%;
  height: 100%;
  background: white;
  mask-image: url('../../../../assets/icons/video-box-off.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}

.VideoSwitchOff {
  width: 100%;
  height: 100%;
  background: white;
  mask-image: url('../../../../assets/icons/video-box.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}
</style>
