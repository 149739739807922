<template>
  <div class="bg">
    <div v-if="loaded && data" class="bg">
      <Visit2D v-if="is2D" :images="data.slides" />
      <Visit360 v-else :data="data" />
    </div>
  </div>
</template>

<script>
import Visit360 from '../visit360/Visit360.vue'
import Visit2D from '../visit2D/Visit2D.vue'
import { GetJSON } from '@/utils/filesManager'

export default {
  data () {
    return {
      is2D: false,
      loaded: false,
      data: ''
    }
  },
  async mounted () {
    try {
      const id = this.$route.params.id
      const data = await GetJSON(
        this.$store.getters['events/getEvents'].default.interfaceContent
          .showroom[id].data.media['en-US'][0].objects.original
      )
      this.data = data
      this.is2D = data.type === '2d'
    } catch (e) {
      console.error(e)
    } finally {
      this.loaded = true
    }
  },
  components: {
    Visit360,
    Visit2D
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    }
  }
}
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
}
</style>
