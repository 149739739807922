import * as THREE from 'three'
import { EventBus } from '@/event-bus.js'
import { SetTargetFOV } from './main'

let camera
let borderDown
let borderUp
let borderLeft
let borderRight
let lastX
let lastY

let zoomValue
let zoomMin
let zoomMax

const debugMode = false

export const InitCamera = () => {
  camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.01,
    1000
  )
  camera.position.set(0, 0, 0)
  camera.target = new THREE.Vector3(0, 0, 0)
}

export const GetCamera = () => {
  return camera
}

export const SetCameraBorders = (down, up, left, right) => {
  borderDown = down
  borderUp = up
  borderLeft = left
  borderRight = right
}

export const SetCameraRotation = (x, y) => {
  y = Math.max(borderDown, Math.min(borderUp, y))
  const phi = THREE.Math.degToRad(90 - y)
  if (borderLeft !== 0 && borderRight !== 0 && !debugMode) {
    x = Math.max(borderLeft, Math.min(borderRight, x))
  }
  if (isNaN(x)) {
    x = 0
  }
  const theta = THREE.Math.degToRad(x)
  lastX = x
  lastY = y
  camera.target.x = 500 * Math.sin(phi) * Math.cos(theta)
  camera.target.y = 500 * Math.cos(phi)
  camera.target.z = 500 * Math.sin(phi) * Math.sin(theta)
  // console.log('x : ' + lastX + ' ; y : ' + lastY)
  camera.lookAt(camera.target)
}

export const SetViewZoomData = zoom => {
  zoomMin = zoom.min || 30
  zoomMax = zoom.max || 80
  zoomValue = zoom.value
  SetTargetFOV(zoom.value)
  camera.fov = zoom.value
}

export const SetCameraFOV = fov => {
  SetTargetFOV(THREE.Math.clamp(fov, zoomMin, zoomMax))
}

export const TransformFOV = correction => {
  const value = THREE.Math.clamp(camera.fov + correction, zoomMin, zoomMax)
  // console.log('zoom : ' + value)
  SetCameraFOV(value)
  if (value < zoomValue) {
    const diff = zoomValue - zoomMin
    const trueVal = value - zoomMin
    const ratio = trueVal / diff
    EventBus.$emit('SetZoom', 1 - ratio / 2)
  } else if (value > zoomValue) {
    const diff = zoomMax - zoomValue
    const trueVal = value - zoomValue
    const ratio = 1 - trueVal / diff
    EventBus.$emit('SetZoom', ratio / 2)
  } else {
    EventBus.$emit('SetZoom', 0.5)
  }
}

export const SetFOVSlider = value => {
  const remap = 1 - value
  if (value < 0.5) {
    const zoom = zoomValue + (zoomMax - zoomValue) * (remap / 2)
    SetCameraFOV(zoom)
  } else if (value > 0.5) {
    const zoom = zoomMin + (zoomValue - zoomMin) * (remap * 2)
    SetCameraFOV(zoom)
  } else {
    SetCameraFOV(zoomValue)
  }
}

export const GetCameraLastX = () => {
  return lastX
}

export const GetCameraLastY = () => {
  return lastY
}
