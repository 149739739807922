<template>
  <div
    class="Panel"
    v-if="
      this.$store.getters['module_visit/mapOpen'] ||
        this.$store.getters['module_visit/cardOpen']
    "
  >
    <Map v-if="this.$store.getters['module_visit/mapOpen']" />
    <Card v-if="this.$store.getters['module_visit/cardOpen']" />
    <div class="CloseButton" @click="ClosePanel()">
      <div class="CloseButton__Icon" />
    </div>
  </div>
</template>

<script>
import Map from './Map'
import Card from './Card'
import SetCardQuery from '@/mixins/query'

export default {
  components: {
    Map,
    Card
  },
  mixins: [SetCardQuery],
  methods: {
    ClosePanel () {
      this.$store.dispatch('module_visit/SetCardOpen', false)
      this.$store.dispatch('module_visit/SetMapOpen', false)
      this.SetCardQuery(this.$route, undefined)
    }
  }
}
</script>

<style scoped lang="scss">
.Panel {
  position: absolute;
  @media (orientation: landscape) and (min-width: 1024px) {
    width: 24%;
  }
  @media (orientation: landscape) and (max-width: 768px) {
    width: 80%;
  }
  @media (orientation: portrait) {
    width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 80%;
  }
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.CloseButton {
  position: fixed;
  margin-left: 80px;
  margin-top: 10px;
  top: 0em;
  width: 40px;
  height: 40px;
  @media (orientation: portrait) {
    margin-left: 1em;
    margin-top: 1em;
  }
  border-radius: 100%;
  background: white;
  border: 1px solid black;
  display: flex;
  pointer-events: auto;
  z-index: 20;
  &__Icon {
    margin: auto;
    background: black;
    width: 60%;
    height: 60%;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-image: url('../../../../../assets/icons/close.svg');
  }
}
</style>
