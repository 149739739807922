<template>
  <div
    class="Container"
    v-if="$store.getters['module_visit/fullscreenData'] !== ''"
  >
    <Carousel
      class="Carousel_cpm"
      :images="$store.getters['module_visit/fullscreenData']"
      :closeFullscreenIcon="true"
      @CloseFC="$store.dispatch('module_visit/SetFullscreenData', '')"
    />
  </div>
</template>

<script>
import Carousel from '@/components/carousel/Carousel'

export default {
  components: {
    Carousel
  }
}
</script>

<style scoped>
.Container {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  z-index: 20;
  display: flex;
  border: 1px solid black;
}

.Carousel_cpm {
  position: relative;
  background-color: white;
  height: 100%;
}
</style>
