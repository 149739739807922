<template>
  <div class="Map__Container" id="Container">
    <div class="Map__ContainerScroll">
      <div class="Map__Title">
        <p class="Map__Title_Text">Zoning</p>
      </div>
      <img
        class="Map_Preview"
        :src="GetImage()"
        ref="Plane"
        @load="PlacePins()"
      />
      <div class="Zoning_Overlay" ref="Overlay">
        <div class="Zoning_OverlayRelative">
          <div
            class="Zoning__Tick Zoning__Pin"
            v-for="(element, index) in $store.getters['module_visit/visitData']
              .pov"
            :key="index"
            @click="GoToView(index)"
            :ref="'chip' + index"
            :style="{
              background:
                index === $store.getters['module_visit/pov']
                  ? 'black'
                  : 'rgb(215, 50,30)'
            }"
          >
            <p class="Zoning_Text">{{ index + 1 }}</p>
          </div>
        </div>
      </div>
      <div class="Map__Title">
        <p class="Map__Title_Text">
          {{
            'Views ' +
              '(' +
              $store.getters['module_visit/visitData'].pov.length +
              ')'
          }}
        </p>
      </div>
      <div class="POV_List">
        <div
          class="POV_Element"
          v-for="(element, index) in $store.getters['module_visit/visitData']
            .pov"
          :key="index"
          :style="{
            background:
              index === $store.getters['module_visit/pov']
                ? 'rgba(100,100,100,0.1)'
                : ''
          }"
        >
          <div class="POV_Main">
            <div
              class="POV_Main_Pastille"
              :style="{
                background:
                  index === $store.getters['module_visit/pov']
                    ? 'black'
                    : 'rgb(215, 50,30)'
              }"
              @click="GoToView(index)"
            >
              <p class="POV_Main_Pastille_Text">{{ index + 1 }}</p>
            </div>
            <div class="POV_Main_Name" @click="GoToView(index)">
              <p
                class="POV_Main_Name_Text"
                :style="{
                  'font-weight':
                    index === $store.getters['module_visit/pov'] ? 800 : 600
                }"
              >
                {{ element.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="Map__Title" v-if="Get3DPins().length > 0">
        <p class="Map__Title_Text">
          {{ 'Informations ' + '(' + Get3DPins().length + ')' }}
        </p>
      </div>
      <div class="Pin_List" v-if="Get3DPins().length > 0">
        <div
          class="POV_Element"
          v-for="(pin, index) in Get3DPins()"
          :key="'A' + index"
        >
          <div class="POV_Main">
            <div class="POV_Main_Pastille" @click="OpenCard(pin)">
              <p class="POV_Main_Pastille_Text">{{ GetAlphaNumeric(index) }}</p>
            </div>
            <div class="POV_Main_Name" @click="OpenCard(pin.id)">
              <p class="POV_Main_Name_Text">
                {{ Get3DPinTitle(pin.id) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import SetCardQuery from '@/mixins/query'

export default {
  data () {
    return {
      openIndex: -1
    }
  },
  mounted () {
    const el = document.getElementById('Container')
    el.scrollTop = this.$store.getters['module_visit/scrollValue']
  },
  mixins: [SetCardQuery],
  methods: {
    Clicked (index) {
      if (this.openIndex === index) {
        this.openIndex = -1
      } else {
        this.openIndex = index
      }
    },
    GetImage () {
      return this.$store.getters['module_visit/mapUrl']
    },
    PlacePins () {
      if (this.$refs.Plane) {
        this.$refs.Overlay.style.height = this.$refs.Plane.height + 'px'
        const bounds = this.$refs.Plane.getBoundingClientRect()
        const left = bounds.left
        const right = bounds.right
        const top = bounds.top
        const bottom = bounds.bottom
        const width = right - left
        const height = bottom - top
        if (width > 0) {
          this.$store.getters['module_visit/visitData'].pov.forEach(
            (element, index) => {
              if (
                !(element.pin2DRatio.x === '' || element.pin2DRatio.y === '')
              ) {
                this.$refs['chip' + index][0].style.left =
                  element.pin2DRatio.x * width - 10 + 'px'
                this.$refs['chip' + index][0].style.top =
                  element.pin2DRatio.y * height - 10 + 'px'
              }
            }
          )
        }
      }
    },
    Get3DPins () {
      return this.$store.getters['module_visit/visitData'].pins3D
    },
    Get3DPinTitle (id) {
      const pins = this.$store.getters['cards/cardsData'].cards
      for (let i = 0; i < pins.length; i++) {
        if (pins[i].id === id) {
          return pins[i].name
        }
      }
      return id
    },
    GoToView (index) {
      EventBus.$emit('GoToPOV', index)
      this.$store.dispatch('module_visit/SetCardOpen', false)
      this.$store.dispatch('module_visit/SetMapOpen', false)
      this.SetCardQuery(this.$route, undefined)
    },
    OpenCard (id) {
      const el = document.getElementById('Container')
      this.$store.dispatch('module_visit/SetScrollValue', el.scrollTop)
      const index = this.FindPOVForCard(id)
      if (index) {
        EventBus.$emit('GoToPOV', index)
      }
      this.$store.dispatch('cards/SetCardID', id)
      this.$store.dispatch('module_visit/SetMapOpen', false)
      this.$store.dispatch('module_visit/SetCardOpen', true)
    },
    FindPOVForCard (id) {
      const povs = this.$store.getters['module_visit/visitData'].pov
      const images360 = this.$store.getters['module_visit/visitData'].images360
      let ref360 = ''
      for (let i = 0; i < images360.length; i++) {
        const img = images360[i]
        if (img.pins3D) {
          for (let j = 0; j < img.pins3D.length; j++) {
            if (img.pins3D[j] === id) {
              ref360 = img
            }
          }
        }
      }
      for (let i = 0; i < povs.length; i++) {
        if (povs[i].ref360 === ref360.id) {
          return i
        }
      }
      return undefined
    },
    GetAlphaNumeric (index) {
      return String.fromCharCode(97 + index).toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>
@media (orientation: landscape) {
  .Map__Container {
    background: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 70px;
    padding-top: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
  }
}

@media (orientation: portrait) {
  .Map__Container {
    background: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 1em;
    padding-top: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
  }
}

.Map_Preview {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Zoning_Overlay {
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 100px;
}

.Zoning_OverlayRelative {
  top: 0;
  width: 100%;
  height: 100%;
}

.Zoning__Tick {
  background: rgb(215, 50, 30);
  border: solid white 1px;
  border-radius: 100%;
  min-width: 13px;
  min-height: 13px;
  max-width: 13px;
  max-height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Zoning__Pin {
  position: absolute;
}

.Zoning_Text {
  margin: auto;
  color: white;
  align-self: center;
  font-size: 0.45em;
  font-family: 'Gotham Light';
}

.Map__Title {
  margin-left: 1em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Map__Title_Text {
  font-family: 'Gotham Black';
  font-size: 0.85em;
}

.POV_List {
  margin: auto;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
}

.Pin_List {
  margin: auto;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 6em;
}

.POV_Element {
  margin: auto;
  min-height: 20px;
  width: 100%;
  border-bottom: solid 1px rgba(80, 80, 80, 0.2);
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
}

.POV_Main {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
}

.POV_Main_Chevron {
  margin-top: auto;
  margin-bottom: auto;
  width: 15px;
  height: 15px;
  background: black;
  mask-image: url('../../../../../assets/icons/chevron_right.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.POV_Main_Pastille {
  min-width: 13px;
  min-height: 13px;
  max-width: 13px;
  max-height: 13px;
  background: rgb(215, 50, 30);
  border-radius: 100%;
  display: flex;
  &_Text {
    color: white;
    margin: auto;
    font-size: 0.45em;
    font-family: 'Gotham Light';
  }
}

.POV_Main_Name {
  margin-left: 0.5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  &_Text {
    text-align: left;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 0.65em;
    font-weight: 600;
    font-family: 'Gotham Book';
  }
}

.POV_Pins {
  width: 100%;
}

.POV_Pin {
  width: 100%;
  height: auto;
  &_Text {
    font-size: 0.7em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 40px;
  }
}
.Map__ContainerScroll {
  width: 100%;
  height: auto;
  overflow-y: scroll;
  touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
}

div,
img,
p {
  touch-action: auto !important;
  -webkit-overflow-scrolling: touch;
}
</style>
