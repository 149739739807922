// https://www.raymondcamden.com/2019/10/16/using-indexeddb-with-vuejs
// https://codepen.io/cfjedimaster/pen/oNNxavx

const DB_NAME = 'images360'
const DB_VERSION = 1

export const GetDb = async () => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB_NAME, DB_VERSION)
    request.onerror = e => {
      console.error('Error opening db', e)
      reject(e)
    }
    request.onsuccess = e => {
      resolve(e.target.result)
    }
    request.onupgradeneeded = e => {
      // console.log('on upgrade needed')
      const db = e.target.result
      db.createObjectStore('images', { autoIncrement: true, keyPath: 'id' })
    }
  })
}

export const GetImagesFromDb = async db => {
  return new Promise(resolve => {
    const trans = db.transaction(['images'], 'readonly')
    trans.oncomplete = () => {
      resolve(images)
    }
    const store = trans.objectStore('images')
    const images = []
    store.openCursor().onsuccess = e => {
      const cursor = e.target.result
      if (cursor) {
        images.push(cursor.value)
        cursor.continue()
      }
    }
  })
}

export const AddImageToDb = async (db, image) => {
  return new Promise(resolve => {
    const trans = db.transaction(['images'], 'readwrite')
    trans.oncomplete = () => {
      resolve()
    }
    const store = trans.objectStore('images')
    store.add(image)
  })
}

export const AddImage = async (db, id, base64, timestamp) => {
  const image = {
    id: id,
    base64: base64,
    timestamp: timestamp
  }
  await AddImageToDb(db, image)
}

export const DeleteImageFromDb = async (db, id) => {
  return new Promise(resolve => {
    const trans = db.transaction(['images'], 'readwrite')
    trans.oncomplete = () => {
      resolve()
    }
    const store = trans.objectStore('images')
    store.delete(id)
  })
}
