import * as THREE from 'three'
import {
  CreateSkysphere,
  SetSkysphereMaterial,
  SetMaskSphereTexture
} from './skysphere'
import { PostProcessProfileA } from './PostProcessHandler'
import {
  Load360Materials,
  LoadColliders,
  ChangeMeshesPosition,
  GetColliders,
  Get360Data,
  LoadVideoMeshes,
  CleanupResources,
  SetScreensVisibility,
  SetDataToLoad
} from './resourcesHandler'
import { RegisterEvents, RemoveEvents } from './eventsHandler'
import {
  InitCamera,
  GetCamera,
  SetCameraBorders,
  SetCameraRotation,
  SetViewZoomData,
  SetFOVSlider
} from './camera'
import {
  CreatePins,
  ChangePinsOffset,
  GetPinsData,
  SetVisiblePins
} from './pins3D'
import {
  InitVideo,
  UpdateVideoTextures,
  GetVideoMaterials,
  SetVideoVisibility,
  DestroyVideos
} from './videoTexture'
import { EventBus } from '@/event-bus.js'
import store from '@/store'
import Stats from 'three/examples/jsm/libs/stats.module.js'

let scene
let renderer
/* eslint-disable-next-line */
let animationId
let visitData
let visitInterfaceContent
let composer
let targetFOV

let povToReach = -1

let pinStored = []

const stats = new Stats()
let statNode

let last360Data
let displayVideos

export const Init = async (data, interfaceContent, displayVids) => {
  displayVideos = displayVids
  visitData = data
  visitInterfaceContent = interfaceContent
  animationId = 0
  SceneSettings()
  CreateSkysphere(scene)
  CreatePins(visitData.pins3D)
  RegisterEvents()
  await SetDataToLoad(visitInterfaceContent)
  await LoadColliders(visitData.pov, scene, visitData.collidersRotation)
  if (displayVideos) {
    InitVideo(visitData.videos, visitInterfaceContent)
    await LoadVideoMeshes(
      visitData.videos,
      scene,
      GetVideoMaterials(),
      visitData.collidersRotation
    )
  }
  composer = PostProcessProfileA(
    renderer,
    scene,
    GetCamera(),
    window.innerWidth,
    window.innerHeight
  )
  await Load360Materials(visitData.images360, visitInterfaceContent)
}

export const StartVisit = () => {
  Animate()
  GoToPOV(0)
  EventBus.$emit('VisitLoaded')
}

// Create scene, background, camera, lights and attach to the div
function SceneSettings () {
  const container = document.getElementById('threejscontainer')
  InitCamera()
  scene = new THREE.Scene()
  scene.name = 'scene'
  renderer = new THREE.WebGLRenderer()
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(window.innerWidth, window.innerHeight)
  container.appendChild(renderer.domElement)
  const light = new THREE.AmbientLight(0x404040, 2) // soft white light
  scene.add(light)
  scene.background = new THREE.Color('black')
  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8)
  scene.add(directionalLight)
}

export const Close = () => {
  pinStored = []
  // cancelAnimationFrame(animationId)
  RemoveEvents()
  if (displayVideos) {
    DestroyVideos()
  }
  CleanupResources()
  CleanScene()
}

export const Animate = () => {
  /* animationId is assigned a value but never used */
  /* eslint-disable-next-line */
  animationId = requestAnimationFrame(Animate)
  renderer.render(scene, GetCamera())
  composer.render()
  if (store.getters['module_visit/videosActive'] && displayVideos) {
    UpdateVideoTextures()
  }
  UpdateFOV()
  UpdatePins()
  if (store.getters['module_visit/isDebug']) {
    stats.update()
  }
}

export const SetDebugStats = debug => {
  if (debug) {
    statNode = document
      .getElementById('threejscontainer')
      .appendChild(stats.dom)
  } else {
    if (statNode) {
      document.getElementById('threejscontainer').removeChild(stats.dom)
      statNode = undefined
    }
  }
}

function UpdateFOV () {
  if (targetFOV && Math.abs(GetCamera().fov - targetFOV) > 0.1) {
    const diff = (targetFOV - GetCamera().fov) / 10
    GetCamera().fov = GetCamera().fov + diff
    GetCamera().updateProjectionMatrix()
  }
}

export const SetTargetFOV = fov => {
  targetFOV = fov
}

export const GoToPOV = index => {
  const pov = visitData.pov[index]
  const data360 = Get360Data(pov.ref360)
  last360Data = data360
  if (data360 !== '') {
    store.dispatch('module_visit/SetPOV', index)
    EventBus.$emit('RaycastGot', index)
    SetSkysphereMaterial(data360.material)
    ChangeMeshesPosition(
      data360.position.x,
      data360.position.y,
      data360.position.z
    )
    ChangePinsOffset(data360.position.x, data360.position.y, data360.position.z)
    SetVisiblePins(data360.pins3D)

    SetCameraBorders(pov.rotMin.y, pov.rotMax.y, pov.rotMin.x, pov.rotMax.x)
    if (displayVideos) {
      SetScreensVisibility(
        data360.videos,
        store.getters['module_visit/videosActive']
      )
      SetVideoVisibility(
        data360.videos,
        store.getters['module_visit/videosActive']
      )
    }
    SetMaskSphereTexture(data360.mask ? data360.mask : '')
    povToReach = -1
    EventBus.$emit('SetZoom', 0.5)
    SetViewZoomData(pov.zoom)
    EventBus.$emit('POVloading', false)
    SetCameraRotation(pov.rot.x, pov.rot.y)
  } else {
    povToReach = index
    EventBus.$emit('POVloading', true)
  }
}

export const UpdateVideoVisibility = () => {
  SetScreensVisibility(
    last360Data.videos,
    store.getters['module_visit/videosActive']
  )
  SetVideoVisibility(
    last360Data.videos,
    store.getters['module_visit/videosActive']
  )
}

export const UpdatePins = () => {
  const data = GetPinsData(GetCamera())
  if (!arraysEqual(data, pinStored)) {
    EventBus.$emit('pinsChanged', data)
    pinStored = data
  }
}

export const ImageLoaded = (index, total, id) => {
  if (povToReach > -1) {
    const pov = visitData.pov[povToReach]
    if (pov.ref360 === id) {
      GoToPOV(povToReach)
    }
  }
  EventBus.$emit('ImagesProgress', (index + 1) / total)
}

export const SetZoom = zoom => {
  SetFOVSlider(zoom)
}

export const Raycast = mouse => {
  const raycaster = new THREE.Raycaster()
  raycaster.setFromCamera(mouse, GetCamera())
  const colliders = GetColliders()
  if (colliders.length > 0) {
    const intersects = raycaster.intersectObjects(colliders, true)
    if (intersects.length > 0) {
      GoToPOV(intersects[0].object.name)
    }
  }
}

export const ResizeWindow = () => {
  GetCamera().aspect = window.innerWidth / window.innerHeight
  GetCamera().updateProjectionMatrix()
  renderer.setSize(window.innerWidth, window.innerHeight)
  composer = PostProcessProfileA(
    renderer,
    scene,
    GetCamera(),
    window.innerWidth,
    window.innerHeight
  )
}

function CleanScene () {
  const names = []
  if (scene) {
    scene.traverse(child => {
      if (child.name) {
        names.push(child.name)
      }
      if (child.geometry) {
        child.geometry.dispose()
      }
      if (child.material && child.material.map) {
        child.material.map.dispose()
      }
      if (child.material) {
        child.material.dispose()
      }
    })
  }
  names.forEach(name => {
    const obj = scene.getObjectByName(name)
    scene.remove(obj)
  })
  if (renderer) {
    renderer.forceContextLoss()
    renderer.renderLists.dispose()
  }
}

function arraysEqual (a, b) {
  if (a === b) return true
  if (a === null || b === null) return false
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; ++i) {
    if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) return false
  }
  return true
}
