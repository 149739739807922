import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { TAARenderPass } from 'three/examples/jsm/postprocessing/TAARenderPass.js'

export const PostProcessProfileA = (renderer, scene, camera, w, h) => {
  const composer = new EffectComposer(renderer)
  const renderPass = new RenderPass(scene, camera)
  const taaRenderPass = new TAARenderPass(scene, camera)
  taaRenderPass.unbiased = false
  taaRenderPass.sampleLevel = 2
  composer.addPass(renderPass)
  composer.addPass(taaRenderPass)
  composer.setSize(w, h)
  return composer
}
