import * as THREE from 'three'
let pinList = []
let offset = new THREE.Vector3(0, 0, 0)
const scaleFactor = 0.001

export const CreatePins = pins3D => {
  pinList = []
  offset = new THREE.Vector3(0, 0, 0)
  if (pins3D) {
    /* map() expects a return value from arrow function , use foreach instead */
    pins3D.forEach(pin3D => {
      pinList.push({
        position: new THREE.Vector3(
          pin3D.position.x,
          pin3D.position.y,
          pin3D.position.z
        ),
        id: pin3D.id,
        visible: true
      })
    })
  }
}

export const SetVisiblePins = ids => {
  for (let i = 0; i < pinList.length; i++) {
    pinList[i].visible = false
    if (ids) {
      for (let j = 0; j < ids.length; j++) {
        if (pinList[i].id === ids[j]) {
          pinList[i].visible = true
          continue
        }
      }
    }
  }
}

export const ChangePinsOffset = (cameraPosX, cameraPosY, cameraPosZ) => {
  offset = new THREE.Vector3(cameraPosX, cameraPosY, cameraPosZ)
}

export const GetPinsData = camera => {
  const width = window.innerWidth
  const height = window.innerHeight
  const widthHalf = width / 2
  const heightHalf = height / 2
  const returnList = []
  for (let i = 0; i < pinList.length; i++) {
    if (!pinList[i].visible) continue
    const pos = new THREE.Vector3(
      -(pinList[i].position.y + offset.y) * scaleFactor,
      (pinList[i].position.z - offset.z) * scaleFactor,
      (pinList[i].position.x - offset.x) * scaleFactor
    )
    if (!IsInFrustrum(camera, pos)) continue
    pos.project(camera)
    pos.x = pos.x * widthHalf + widthHalf
    pos.y = -(pos.y * heightHalf) + heightHalf
    returnList.push({
      x: pos.x,
      y: pos.y,
      id: pinList[i].id
    })
  }
  return returnList
}

function IsInFrustrum (camera, position) {
  const frustum = new THREE.Frustum()
  const projScreenMatrix = new THREE.Matrix4()
  projScreenMatrix.multiplyMatrices(
    camera.projectionMatrix,
    camera.matrixWorldInverse
  )
  frustum.setFromProjectionMatrix(
    new THREE.Matrix4().multiplyMatrices(
      camera.projectionMatrix,
      camera.matrixWorldInverse
    )
  )
  return frustum.containsPoint(position)
}
