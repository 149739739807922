import * as THREE from 'three'

let videoList = []
let interfaceContent

export const InitVideo = (videos, data) => {
  interfaceContent = data
  videoList = []
  for (let i = 0; i < videos.length; i++) {
    const video = document.createElement('video')
    video.crossOrigin = 'anonymous'
    video.src =
      interfaceContent.videos[videos[i].source].media[
        'en-US'
      ][0].objects.original
    video.muted = true
    video.load()
    video.play()
    video.loop = true
    const videocanvas = document.createElement('canvas')
    const videocanvasctx = videocanvas.getContext('2d')
    videocanvas.width = videos[i].resX
    videocanvas.height = videos[i].resY
    videocanvasctx.fillStyle = '#000000'
    videocanvasctx.fillRect(0, 0, 640, 480)
    const videoTexture = new THREE.Texture(videocanvas)
    videoTexture.minFilter = THREE.LinearFilter
    const videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture })
    videoList.push({
      id: videos[i].id,
      video: video,
      context: videocanvasctx,
      texture: videoTexture,
      material: videoMaterial,
      visible: false
    })
  }
}

export const UpdateVideoTextures = () => {
  videoList.forEach(videoObject => {
    if (videoObject.visible) {
      videoObject.context.drawImage(videoObject.video, 0, 0)
      videoObject.texture.needsUpdate = true
    }
  })
}

export const GetVideoMaterials = () => {
  const returnList = []
  videoList.forEach(videoObject => {
    returnList.push(videoObject.material)
  })
  return returnList
}

export const SetVideoVisibility = (ids, filter) => {
  for (let i = 0; i < videoList.length; i++) {
    let found = false
    if (ids && filter) {
      for (let j = 0; j < ids.length; j++) {
        if (videoList[i].id === ids[j]) {
          videoList[i].visible = true
          found = true
          break
        }
      }
    }
    videoList[i].video.play().then(() => {
      if (!found && videoList[i] && videoList[i].video) {
        videoList[i].video.pause()
        videoList[i].visible = false
      }
    })
  }
}

export const DestroyVideos = () => {
  videoList = []
}

export const retrieveUrl = key => {
  return interfaceContent[key].media[0].objects.original
}
